import React from "react";

export const AnswersQuestions = ({ timer, pregunta, handleAnswer }) => {
  return (
    <div
      className="w-full overflow-hidden relative p-2 md:p-4 lg:p-8
                mx-2 md:mx-4 lg:mx-14 xl:mx-20 my-4 md:my-8 lg:my-16"
    >
      <p className="text-lg md:text-xl text-cyan-800">Pregunta:</p>
      <div className="flex justify-center items-center mt-4 w-full">
        <p className="text-2xl md:text-3xl text-cyan-800">
          {timer ? `${timer.toFixed(2)} segundos` : ""}
        </p>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: pregunta.pregunta }}
        className="bg-[#E7F4F5] rounded-lg w-[93%] md:w-4/5 p-4 mt-5 shadow-lg text-base md:text-lg text-gray-900"
      ></div>
      <div className="mt-8 grid grid-cols-2 gap-4 w-[93%] md:w-[80%]">
        {pregunta.opciones.map((respuesta) => (
          <div
            key={respuesta.id}
            className="bg-[#E7F4F5] rounded-lg w-full p-4 mt-5 shadow-lg
                                cursor-pointer hover:bg-cyan-100 transition duration-300 ease-linear"
            onClick={() => handleAnswer(respuesta)}
          >
            <p
              className="text-base md:text-lg text-gray-700
                                    text-center
                                    "
            >
              {respuesta.texto}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
