import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import { fetchConToken } from '../../helpers/fetch';
import logo from '../../assets/dermatrivia_horizontal_fullcolor.svg';
import animation from '../../assets/confeti.json';

const Podium = () => {

    const [list, setList] = useState([]);

    useEffect(() => {
        const getUsuarios = async() => {
            const res = await fetchConToken('trivia/ranking');
            const data = await res.json();
            setList(data);
        }
        getUsuarios();
    }, []);

    return (
        <div
            className='w-full h-full flex flex-col justify-center items-center mt-5 md:mt-10'
        >
            <div className='absolute top-0 left-0 right-0 z-50' >
                <Lottie
                    animationData={animation}
                    style={{ height: "100%", width: "100%" }}
                />
            </div>

            <img
                src={logo}
                className='w-[300px] md:w-[400px] lg:w-[500px]'
                alt="Dermatrivia Logo"
            />

            <div className='flex mt-5 items-center justify-center md:mt-10 w-[100%] md:w-[80%] mx-auto'>
                <div className='w-[30%] md:w-1/4 h-[85vh] bg-[#0A4C50] relative bottom-0 shadow-sm
                animate__animated animate__fadeInUp animate__delay-1s'>
                    <div
                        className='w-full h-1/2 bg-white relative
                        top-0 justify-center items-center flex flex-col'
                    >
                    <div
                            className='absolute bottom-0 h-[65%] w-full'
                        >
                            <Lottie
                                animationData={animation}
                                style={{ height: "100%", width: "100%" }}
                            />
                        </div>
                        <div
                            className='justify-center items-center flex flex-col absolute bottom-3'
                        >
                            <p
                                className="h-[30px] text-lg md:text-xl font-semibold text-center"
                            >
                                {list[1]?.nombreCompleto}
                            </p>
                            <p className="text-gray-400">
                                {list[1]?.apodo}
                            </p>
                            <img
                                src={list[1]?.avatar}
                                alt="avatar"
                                className="w-[70px] h-[70px] "
                            />
                        </div>
                    </div>

                    <div
                        className='flex flex-col justify-center items-center mt-3 w-[70%] mx-auto'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="81.652" height="85.589" viewBox="0 0 81.652 85.589">
                            <g id="Group_2092" data-name="Group 2092" transform="translate(-314.282 -463.408)">
                                <text id="_2" data-name="2" transform="translate(355 501.888)" fill="#fff" font-size="26" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="-7.462" y="0">2</tspan></text>
                                <g id="noun-medal-3593764" transform="translate(315.227 464)">
                                    <path id="Path_177" data-name="Path 177" d="M67.66,33.088a21.35,21.35,0,1,0-21.35,21.4A21.4,21.4,0,0,0,67.66,33.088Zm-21.35,19.7a19.7,19.7,0,1,1,19.645-19.7A19.694,19.694,0,0,1,46.31,52.787Z" transform="translate(-6.429 -2.851)" fill="#fff" stroke="#fff" stroke-width="1" />
                                    <path id="Path_178" data-name="Path 178" d="M65.76,48.012l5.218-2.993-1.127-7.336,4.182-6.121-4.182-6.121L70.978,18.1l-6.425-3.686-2.161-7.1-7.381-.537-5.02-5.456L43.108,4.065l-6.883-2.74L31.207,6.781l-7.383.537-2.16,7.1L15.237,18.1l1.127,7.336-4.182,6.121,4.182,6.121-1.126,7.336,5.218,2.994-17.23,26.1,12.508-.31,4.1,11.411L35.679,61.205l.547.594,6.882-2.74,6.883,2.74.547-.593L66.385,85.212l4.1-11.41,12.508.31ZM20.285,81.429l-3.361-9.362-10.475.26L21.782,49.1,23.824,55.8l7.382.539,3.284,3.57Zm22.823-24.2-6.422,2.556L32,54.69l-6.889-.5L23.1,47.562l-6-3.441,1.051-6.844-3.905-5.714,3.905-5.714L17.1,19l6-3.44,2.016-6.626,6.89-.5,4.682-5.09L43.108,5.9l6.423-2.556,4.682,5.09,6.889.5,2.017,6.626L69.114,19l-1.05,6.846,3.9,5.714-3.9,5.714,1.05,6.845-6,3.44L61.1,54.187l-6.889.5-4.682,5.091ZM69.293,72.067,65.932,81.43,51.727,59.912l3.284-3.57,7.381-.538L64.434,49.1,79.767,72.326Z" transform="translate(-3.227 -1.325)" fill="#fff" stroke="#fff" stroke-width="1" />
                                </g>
                            </g>
                        </svg>

                        <br />
                        <p
                            className="text-white text-lg md:text-2xl font-semibold text-center"
                        >
                            {list[1]?.puntos} pts
                        </p>
                    </div>
                </div>
                <div className='w-[30%] md:w-1/4 h-[85vh] bg-[#05393C] relative bottom-0 shadow-sm
                animate__animated animate__fadeInUp'>
                    <div
                        className='w-full h-1/3 bg-white relative
                        top-0 justify-center items-center flex flex-col'
                    >
                        <div
                            className='absolute bottom-0 h-[95%] w-full'
                        >
                            <Lottie
                                animationData={animation}
                                style={{ height: "100%", width: "100%" }}
                            />
                        </div>
                        <div
                            className='justify-center items-center flex flex-col absolute bottom-3'
                        >
                            <p
                                className="h-[30px] text-lg md:text-xl font-semibold text-center"

                            >
                                {list[0]?.nombreCompleto}
                            </p>
                            <p className="text-gray-400">
                                {list[0]?.apodo}
                            </p>
                            <img
                                src={list[0]?.avatar}
                                alt="avatar"
                                className="w-[70px] h-[70px] "
                            />
                        </div>
                    </div>
                    <div
                        className='flex flex-col justify-center items-center mt-3 w-[70%] mx-auto'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="81.653" height="85.589" viewBox="0 0 81.653 85.589">
                            <g id="Group_2115" data-name="Group 2115" transform="translate(-314.282 -377.824)">
                                <text id="_1" data-name="1" transform="translate(350.668 420.356)" fill="#fff" font-size="28" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">1</tspan></text>
                                <g id="noun-medal-3593764" transform="translate(315.227 378.415)">
                                    <path id="Path_177" data-name="Path 177" d="M67.66,33.088a21.35,21.35,0,1,0-21.35,21.4A21.4,21.4,0,0,0,67.66,33.088Zm-21.35,19.7a19.7,19.7,0,1,1,19.645-19.7A19.694,19.694,0,0,1,46.31,52.787Z" transform="translate(-6.429 -2.851)" fill="#fff" stroke="#fff" stroke-width="1" />
                                    <path id="Path_178" data-name="Path 178" d="M65.761,48.012l5.218-2.993-1.127-7.336,4.182-6.121-4.182-6.121L70.978,18.1l-6.425-3.686-2.161-7.1-7.381-.537-5.02-5.456L43.108,4.065l-6.883-2.74L31.207,6.781l-7.383.537-2.16,7.1L15.237,18.1l1.127,7.336-4.182,6.121,4.182,6.121-1.126,7.336,5.218,2.994-17.23,26.1,12.508-.31,4.1,11.411L35.679,61.205l.547.594,6.882-2.74,6.883,2.74.547-.593L66.385,85.212l4.1-11.41,12.508.31ZM20.285,81.429l-3.361-9.362-10.475.26L21.782,49.1,23.824,55.8l7.382.539,3.284,3.57Zm22.823-24.2-6.422,2.556L32,54.69l-6.889-.5L23.1,47.562l-6-3.441,1.051-6.844-3.905-5.714,3.905-5.714L17.1,19l6-3.44,2.016-6.626,6.89-.5,4.682-5.09L43.108,5.9l6.423-2.556,4.682,5.09,6.889.5,2.017,6.626L69.114,19l-1.05,6.846,3.9,5.714-3.9,5.714,1.05,6.845-6,3.44L61.1,54.187l-6.889.5-4.682,5.091ZM69.293,72.067,65.932,81.43,51.727,59.912l3.284-3.57,7.381-.538L64.434,49.1,79.767,72.326Z" transform="translate(-3.227 -1.325)" fill="#fff" stroke="#fff" stroke-width="1" />
                                </g>
                            </g>
                        </svg>
                        <br />
                        <p
                            className="text-white text-lg md:text-2xl font-semibold text-center"
                        >
                            {list[0]?.puntos} pts.
                        </p>
                    </div>
                </div>
                <div className='w-[30%] md:w-1/4 h-[85vh] bg-[#009097] relative bottom-0 shadow-sm
                    animate__animated animate__fadeInUp animate__delay-2s'>
                    <div
                        className='w-full h-[60%] bg-white relative
                        top-0 justify-center items-center flex flex-col'
                    >
                        <div
                            className='absolute bottom-0 h-[60%]'
                        >
                            <Lottie
                                animationData={animation}
                                style={{ height: "100%", width: "100%" }}
                            />
                        </div>
                        <div
                            className='justify-center items-center flex flex-col absolute bottom-3'
                        >
                            <p
                                className="h-[30px] text-lg md:text-xl font-semibold text-center"
                            >
                                {list[2]?.nombreCompleto}
                            </p>
                            <p className="text-gray-400">
                                {list[2]?.apodo}
                            </p>
                            <img
                                src={list[2]?.avatar}
                                alt="avatar"
                                className="w-[70px] h-[70px] "
                            />
                        </div>
                    </div>

                    <div
                        className='flex flex-col justify-center items-center mt-3 w-[70%] mx-auto'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="81.653" height="85.589" viewBox="0 0 81.653 85.589">
                            <g id="Group_2116" data-name="Group 2116" transform="translate(-314.282 -463.408)">
                                <text id="_3" data-name="3" transform="translate(355 501.888)" fill="#fff" font-size="26" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="-7.787" y="0">3</tspan></text>
                                <g id="noun-medal-3593764" transform="translate(315.227 464)">
                                    <path id="Path_177" data-name="Path 177" d="M67.66,33.088a21.35,21.35,0,1,0-21.35,21.4A21.4,21.4,0,0,0,67.66,33.088Zm-21.35,19.7a19.7,19.7,0,1,1,19.645-19.7A19.694,19.694,0,0,1,46.31,52.787Z" transform="translate(-6.429 -2.851)" fill="#fff" stroke="#fff" stroke-width="1" />
                                    <path id="Path_178" data-name="Path 178" d="M65.76,48.012l5.218-2.993-1.127-7.336,4.182-6.121-4.182-6.121L70.978,18.1l-6.425-3.686-2.161-7.1-7.381-.537-5.02-5.456L43.108,4.065l-6.883-2.74L31.207,6.781l-7.383.537-2.16,7.1L15.237,18.1l1.127,7.336-4.182,6.121,4.182,6.121-1.126,7.336,5.218,2.994-17.23,26.1,12.508-.31,4.1,11.411L35.679,61.205l.547.594,6.882-2.74,6.883,2.74.547-.593L66.385,85.212l4.1-11.41,12.508.31ZM20.285,81.429l-3.361-9.362-10.475.26L21.782,49.1,23.824,55.8l7.382.539,3.284,3.57Zm22.823-24.2-6.422,2.556L32,54.69l-6.889-.5L23.1,47.562l-6-3.441,1.051-6.844-3.905-5.714,3.905-5.714L17.1,19l6-3.44,2.016-6.626,6.89-.5,4.682-5.09L43.108,5.9l6.423-2.556,4.682,5.09,6.889.5,2.017,6.626L69.114,19l-1.05,6.846,3.9,5.714-3.9,5.714,1.05,6.845-6,3.44L61.1,54.187l-6.889.5-4.682,5.091ZM69.293,72.067,65.932,81.43,51.727,59.912l3.284-3.57,7.381-.538L64.434,49.1,79.767,72.326Z" transform="translate(-3.227 -1.325)" fill="#fff" stroke="#fff" stroke-width="1" />
                                </g>
                            </g>
                        </svg>
                        <br />
                        <p
                            className="text-white text-lg md:text-2xl font-semibold text-center"
                        >
                            {list[2]?.puntos} pts.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Podium