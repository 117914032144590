

import '../index.css'
export const SpinnerLoading = (
	{ isFullPage = true }
) => {
	return (
		<div className={
			isFullPage ? "fixed top-[40%] left-[45%] " : ""
		}>
			<div className="loader"
				style={{
					fontSize: isFullPage ? "100px" :"50px",
				}}
			></div>
		</div>
	);
};
