import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import DermaTrivia from "../components/DermaTrivia";
import { ModalInitSession } from "../components/ModalInitSession";
import { ModalNextQuestions } from "../components/ModalNextQuestions";
// import { ModalNextQuestions } from '../components/ModalNextQuestions';
import { WaitingPlayers } from "./WaitingPlayers";
import { socket } from "../socket";
import { ValidateToken } from "../helpers/validateToken";
const { fetchConToken } = require("../helpers/fetch");

const GamePage = () => {
  const tokenValid = ValidateToken();

  const [showWaitingPlayers, setShowWaitingPlayers] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(null);
  const [userConected, setUserConected] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [points, setPoints] = useState(0);
  const [modalType, setModalType] = useState("");

  const manageNewQuestion = (data) => {
    setActiveQuestion(data);
    setShowWaitingPlayers(false);
    setShowModal(false);
    const answeredQuestions = localStorage.getItem("answeredQuestions") ? JSON.parse(localStorage.getItem("answeredQuestions")) :  null;
    if (answeredQuestions && answeredQuestions.includes(data.id)) {
      setModalType("next");
      setShowWaitingPlayers(false);
      setShowModal(true);
    } else {
      setTimer(data.timer);
      localStorage.setItem("timer", data.timer);
    }
  };

  useEffect(() => {
    /*** Users Connected */
    const handleUserConnected = (data) => {
      setUserConected(data);
    };

    const handleNextQuestion = (data) => {
      manageNewQuestion(data);
    };

    socket.on("connected users", handleUserConnected);
    socket.on("next question", handleNextQuestion);

    const fetchActiveQuestion = async () => {
      const resp = await fetchConToken("trivia/active-question");
      if (resp.ok) {
        const data = await resp.json();
        manageNewQuestion(data);
      } else {
        setShowWaitingPlayers(true);
        setShowModal(false);
        setTimer(0);
        localStorage.setItem("timer", 0);
      }
    };
    fetchActiveQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("answeredQuestions")) {
      localStorage.setItem("answeredQuestions", JSON.stringify([]));
    }
  }, []);

  const sendAnswerToServer = useCallback(
    async (answer, points) => {
      if (answer) {
        fetchConToken(
          "trivia/answer",
          {
            questionId: activeQuestion.id,
            answer,
            points,
          },
          "POST"
        );
        if (points > 0) {
          setTimer(null);
          setPoints(points);
          setModalType("correct");
          setShowModal(true);
          localStorage.removeItem("timer");
        } else {
          setTimer(null);
          setPoints(0);
          setModalType("incorrect");
          setShowModal(true);
          localStorage.removeItem("timer");
        }
      }
    },
    [activeQuestion]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (!showWaitingPlayers && timer != null) {
        // crea un cuenta regresiva apartir del valor de timer
        const newTimer = timer - 0.01;
        setTimer(newTimer);
        if (newTimer > 0) {
          localStorage.setItem("timer", newTimer.toFixed(2));
        }
        if (newTimer < 0) {
          setPoints(0);
          setTimer(0);
          localStorage.setItem(
            "answeredQuestions",
            JSON.stringify([
              ...JSON.parse(localStorage.getItem("answeredQuestions")),
              activeQuestion.id,
            ])
          );
          localStorage.removeItem("timer");
        }
      }
    }, 10);
    if (timer <= 0 && timer != null) {
      clearInterval(interval);
      setModalType("incorrect");
      setShowModal(true);
    }
    if (timer === null) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWaitingPlayers, timer]);

  const clearModal = () => {
    setPoints(0);
    setModalType("next");
  };

  return !tokenValid ? (
    <ModalInitSession />
  ) : (
    <div>
      {showWaitingPlayers ? (
        <WaitingPlayers userConected={userConected} />
      ) : (
        <div>
          <DermaTrivia
            timer={timer}
            activeQuestion={activeQuestion}
            sendAnswerToServer={sendAnswerToServer}
            setShowModal={setShowModal}
          />
          {showModal && (
            <ModalNextQuestions
              clearModal={clearModal}
              type={modalType}
              points={points}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GamePage;
