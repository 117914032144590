import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SpinnerLoading } from "../components/SpinnerLoading";
import AvatarName from "../pages/AvatarName";
import AvatarSelector from "../pages/AvatarSelector";
import { PrivateRoutes } from "./PrivateRoute";
import GamePage from "../pages/GamePage";
import ViewAllUser from "../pages/admin/ViewAllUser";
import Podium from "../pages/admin/Podium";
import { CurrentQuestion } from "../pages/admin/CurrentQuestion";

const Login = lazy(() => import("../pages/Login"));
const Home = lazy(() => import("../pages/Home"));
const Scanner = lazy(() => import("../pages/Scanner"));
const Questions = lazy(() => import("../pages/Questions"));
const Buy = lazy(() => import("../pages/Buy"));
const AppSelector = lazy(() => import("../pages/AppSelector"));
const EndoTrivia = lazy(() => import("../pages/EndoTrivia"));

export const App = () => {
  
  
  return (
    <Suspense fallback={<SpinnerLoading />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppSelector />} />
          <Route path="/endotrivia" element={<EndoTrivia />} />
          <Route path="/endopoints-login" element={<Login />} />
          <Route path="/avatar" element={<AvatarSelector />} />
          <Route path="/avatar/name/:id" element={<AvatarName />} />
          <Route path="/dermatrivia" element={<GamePage />} />
          
          { /* ---------------------- Admin ---------------------- */ }
          <Route path="/admin/ranking" element={<ViewAllUser />} />
          <Route path="/admin/podium" element={<Podium />} />
          <Route path="/admin/currentQuestion" element={<CurrentQuestion />} />

          <Route
            path="home"
            element={
              <PrivateRoutes>
                <Home />
              </PrivateRoutes>
            }
          />
          <Route
            path="scanner"
            element={
              <PrivateRoutes>
                <Scanner />
              </PrivateRoutes>
            }
          />
          <Route
            path="question/:id"
            element={
              <PrivateRoutes>
                <Questions />
              </PrivateRoutes>
            }
          />
          <Route
            path="buy/:id"
            element={
              <PrivateRoutes>
                <Buy />
              </PrivateRoutes>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
