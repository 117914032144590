import React from "react";
import { LayoutEndotrivia } from "../components/LayoutEndotrivia";
import Control from "../assets/Control.svg";
import { SpinnerLoading } from "../components/SpinnerLoading";

export const WaitingPlayers = ({ userConected }) => {
  const maxUsers = 75;

  return (
    <LayoutEndotrivia>
      <div className="mt-8 md:mt-10">
        <img
          src={Control}
          alt="loading"
          className="w-1/3 mx-auto animate-wiggle animate-infinite animate-duration-500 animate-delay-500 animate-ease-in"
        />
      </div>
      <br />
      <p>Esperando jugadores</p>
      <br />
      <div
        className="h-14 w-14 lg:h-16 lg:w-16 
      bg-gray-100 rounded-md flex flex-col justify-center items-center shadow-xl
      text-cyan-900 font-semibold text-2xl lg:text-3xl
      "
      >
        {userConected}
      </div>
      <br />
      {
        // create a pogress bar
        <div className="relative pt-1 w-[80%]">
          <div className="overflow-hidden w-full h-3 md:h-5 mb-4 text-xs flex rounded-full bg-cyan-100">
            <div
              style={{
                width: `${(userConected * 100) / maxUsers}%`,
              }}
              className="shadow-none 
            transition-all duration-500 ease-in-out
          flex flex-col text-center whitespace-nowrap text-white justify-center bg-cyan-800"
            ></div>
          </div>
        </div>
      }
      <SpinnerLoading />
    </LayoutEndotrivia>
  );
};
