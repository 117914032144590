import React from "react";
import Control from "../assets/Control.svg";
import { SpinnerLoading } from "./SpinnerLoading";

export const ModalNextQuestions = ({
  type = "next",
  points = 0,
  clearModal,
}) => {
  return (
    <div
      className="fixed inset-0 overflow-y-auto h-full w-full z-50
            bg-[#05393C] bg-opacity-90 flex justify-center items-center"
    >
      <div
        className="w-5/6 h-2/4 relative
                 flex flex-col px-8 justify-center items-center shadow-lg rounded-md bg-white"
      >
        {type === "next" && <Next />}
        {type === "correct" && (
          <Correct points={points} clearModal={clearModal} />
        )}
        {type === "incorrect" && <Incorrect clearModal={clearModal} />}
      </div>
    </div>
  );
};

const Next = () => {
  return (
    <div>
      <img
        src={Control}
        className="w-[55px] md:w-[80px] lg:w-[100px] 
        mx-auto animate-wiggle animate-infinite -bottom-8 relative
        animate-duration-500 animate-delay-500 animate-ease-in"
        alt="logo endopoints"
      />
      <p
        className="text-base md:text-lg lg:text-xl text-cyan-900 font-semibold mt-4
    -bottom-10 relative text-center uppercase
    "
      >
        Esperando la siguiente pregunta …
      </p>
      <div className="-bottom-5 relative">
        <SpinnerLoading isFullPage={false} />
      </div>
    </div>
  );
};

const Correct = ({ points, clearModal }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55.342"
        height="55.342"
        viewBox="0 0 55.342 55.342"
      >
        <path
          id="check_circle_FILL0_wght400_GRAD0_opsz24"
          d="M103.8-839.6l19.508-19.508-3.874-3.874L103.8-847.348l-7.886-7.886-3.874,3.874Zm3.874,14.942a26.944,26.944,0,0,1-10.792-2.179,27.944,27.944,0,0,1-8.786-5.915,27.944,27.944,0,0,1-5.915-8.786A26.945,26.945,0,0,1,80-852.329a26.945,26.945,0,0,1,2.179-10.792,27.943,27.943,0,0,1,5.915-8.786,27.942,27.942,0,0,1,8.786-5.915A26.946,26.946,0,0,1,107.671-880a26.946,26.946,0,0,1,10.792,2.179,27.942,27.942,0,0,1,8.786,5.915,27.943,27.943,0,0,1,5.915,8.786,26.945,26.945,0,0,1,2.179,10.792,26.945,26.945,0,0,1-2.179,10.792,27.944,27.944,0,0,1-5.915,8.786,27.944,27.944,0,0,1-8.786,5.915A26.944,26.944,0,0,1,107.671-824.658Zm0-5.534a21.365,21.365,0,0,0,15.7-6.434,21.365,21.365,0,0,0,6.434-15.7,21.365,21.365,0,0,0-6.434-15.7,21.365,21.365,0,0,0-15.7-6.433,21.365,21.365,0,0,0-15.7,6.433,21.365,21.365,0,0,0-6.434,15.7,21.365,21.365,0,0,0,6.434,15.7A21.365,21.365,0,0,0,107.671-830.192ZM107.671-852.329Z"
          transform="translate(-80 880)"
          fill="#069700"
        />
      </svg>
      <p className="text-base md:text-lg lg:text-xl text-cyan-900 font-semibold mt-4 text-center">
        ¡Felicitaciones!
      </p>
      <h3
        className="text-2xl md:text-2xl lg:text-4xl text-center
            text-green-600 font-bold mt-4"
      >
        + {points} puntos
      </h3>
      <button
        className="bg-cyan-900 text-white font-semibold text-base md:text-lg lg:text-xl
            px-4 py-2 rounded-md mt-4"
        onClick={() => {
          clearModal();
        }}
      >
        Aceptar
      </button>
    </div>
  );
};

const Incorrect = ({ clearModal }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55.34"
        height="55.34"
        viewBox="0 0 55.34 55.34"
      >
        <path
          id="cancel_FILL0_wght400_GRAD0_opsz24"
          d="M97.709-838.495l9.961-9.961,9.961,9.961,3.874-3.874-9.961-9.961,9.961-9.961-3.874-3.874L107.67-856.2l-9.961-9.961-3.874,3.874,9.961,9.961-9.961,9.961Zm9.961,13.835a26.943,26.943,0,0,1-10.791-2.179,27.942,27.942,0,0,1-8.785-5.914,27.943,27.943,0,0,1-5.914-8.785A26.944,26.944,0,0,1,80-852.33a26.944,26.944,0,0,1,2.179-10.791,27.943,27.943,0,0,1,5.914-8.785,27.943,27.943,0,0,1,8.785-5.914A26.944,26.944,0,0,1,107.67-880a26.944,26.944,0,0,1,10.791,2.179,27.943,27.943,0,0,1,8.785,5.914,27.942,27.942,0,0,1,5.914,8.785,26.944,26.944,0,0,1,2.179,10.791,26.944,26.944,0,0,1-2.179,10.791,27.942,27.942,0,0,1-5.914,8.785,27.942,27.942,0,0,1-8.785,5.914A26.943,26.943,0,0,1,107.67-824.66Zm0-5.534a21.364,21.364,0,0,0,15.7-6.433,21.364,21.364,0,0,0,6.433-15.7,21.364,21.364,0,0,0-6.433-15.7,21.364,21.364,0,0,0-15.7-6.433,21.364,21.364,0,0,0-15.7,6.433,21.364,21.364,0,0,0-6.433,15.7,21.364,21.364,0,0,0,6.433,15.7A21.364,21.364,0,0,0,107.67-830.194ZM107.67-852.33Z"
          transform="translate(-80 880)"
          fill="#b90b0b"
        />
      </svg>

      <p className="text-base md:text-lg lg:text-xl text-gray-400  mt-4 text-center">
        ¡Mejor suerte para la próxima!
      </p>
      <h3
        className="text-2xl md:text-2xl lg:text-4xl text-center
        text-red-600 font-bold mt-4"
      >
        + 0 puntos
      </h3>
      <button
        className="bg-cyan-900 text-white font-semibold text-base md:text-lg lg:text-xl
            px-4 py-2 rounded-md mt-4"
        onClick={() => {
          clearModal();
        }}
      >
        Aceptar
      </button>
    </div>
  );
};
