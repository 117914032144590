import React, { useEffect, useState } from 'react'
import logo from '../../assets/dermatrivia_horizontal_fullcolor.svg';
import { fetchConToken } from '../../helpers/fetch';


const ViewAllUser = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        const getUsuarios = async() => {
            const res = await fetchConToken('trivia/ranking');
            const data = await res.json();
            setList(data);
        }
        getUsuarios();
    }, []);
    

    return (
        <div
            className='w-full h-full flex flex-col justify-center items-center mt-5 md:mt-10'
        >
            <img
                src={logo}
                className='w-[300px] md:w-[400px] lg:w-[500px]'
                alt="Dermatrivia Logo"
            />

            <table
                className='w-[80%] mt-5'
            >
                <thead
                    className='bg-[#0A4C50] flex justify-center items-center h-10 md:h-14 text-white
                    text-xl font-bold md:text-2xl lg:text-3xl xl:text-4xl rounded-t-xl'
                >
                    <tr>
                        Clasificaciones
                    </tr>
                </thead>
                <tbody
                    className='rounded-lg'
                >
                    {
                        list.map((item, index) => (
                            <>
                                <br />
                                <tr
                                    key={index}
                                    className='shadow-lg flex gap-4 relative items-center'
                                    style={{
                                        borderRadius: '8px',
                                        backgroundColor: index < 3 ? '#E7F4F5' : '#fafafa'
                                    }}
                                >
                                    <td
                                        className='text-center'
                                    >
                                        <img
                                            src={item.avatar}
                                            alt="avatar"
                                            className="w-[70px] h-[70px] rounded-lg"
                                        />
                                    </td>
                                    <td>
                                        <div>
                                            {
                                                index === 0 ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48.641" height="50.87" viewBox="0 0 48.641 50.87">
                                                        <g id="Group_2089" data-name="Group 2089" transform="translate(-314.282 -377.824)">
                                                            <text id="_1" data-name="1" transform="translate(336 403)" fill="#0a4c50" font-size="16" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">1</tspan></text>
                                                            <g id="noun-medal-3593764" transform="translate(312 377.091)">
                                                                <path id="Path_177" data-name="Path 177" d="M49.988,24.229A12.514,12.514,0,1,0,37.474,36.775,12.544,12.544,0,0,0,49.988,24.229ZM37.474,35.775A11.546,11.546,0,1,1,48.988,24.229,11.543,11.543,0,0,1,37.474,35.775Z" transform="translate(-10.872 -5.182)" fill="#0a4c50" stroke="#0a4c50" stroke-width="1" />
                                                                <path id="Path_178" data-name="Path 178" d="M39.879,28.689l3.059-1.754-.661-4.3,2.451-3.588L42.277,15.46l.66-4.3L39.172,9,37.905,4.838l-4.326-.315-2.943-3.2L26.6,2.931,22.568,1.325l-2.942,3.2L15.3,4.838,14.033,9l-3.767,2.16.661,4.3L8.476,19.047l2.451,3.588-.66,4.3,3.059,1.755-10.1,15.3,7.331-.181,2.4,6.688,9.288-14.072.321.348L26.6,35.165l4.034,1.606.32-.348,9.289,14.071,2.4-6.688,7.331.181ZM13.225,48.276l-1.97-5.487-6.14.152L14.1,29.326l1.2,3.93,4.327.316,1.925,2.092ZM26.6,34.089l-3.764,1.5L20.093,32.6l-4.038-.294-1.182-3.884-3.515-2.017.616-4.012L9.686,19.048,11.975,15.7l-.616-4.012L14.874,9.67l1.181-3.884,4.039-.294,2.744-2.984,3.764,1.5,3.765-1.5,2.744,2.984,4.038.294,1.182,3.884,3.514,2.016L41.23,15.7l2.288,3.349L41.23,22.4l.616,4.012L38.33,28.425l-1.181,3.884-4.038.294-2.744,2.984Zm15.348,8.7-1.97,5.488L31.654,35.664l1.925-2.092,4.326-.315,1.2-3.931,8.987,13.615Z" transform="translate(0)" fill="#0a4c50" stroke="#0a4c50" stroke-width="1" />
                                                            </g>
                                                        </g>
                                                    </svg>

                                                ) : index === 1 ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48.641" height="50.87" viewBox="0 0 48.641 50.87">
                                                        <g id="Group_2092" data-name="Group 2092" transform="translate(-314.282 -463.408)">
                                                            <text id="_2" data-name="2" transform="translate(339 488)" fill="#0a4c50" font-size="16" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="-4.592" y="0">2</tspan></text>
                                                            <g id="noun-medal-3593764" transform="translate(312 462.675)">
                                                                <path id="Path_177" data-name="Path 177" d="M49.988,24.229A12.514,12.514,0,1,0,37.474,36.775,12.544,12.544,0,0,0,49.988,24.229ZM37.474,35.775A11.546,11.546,0,1,1,48.988,24.229,11.543,11.543,0,0,1,37.474,35.775Z" transform="translate(-10.872 -5.182)" fill="#0a4c50" stroke="#0a4c50" stroke-width="1" />
                                                                <path id="Path_178" data-name="Path 178" d="M39.879,28.689l3.059-1.754-.661-4.3,2.451-3.588L42.277,15.46l.66-4.3L39.172,9,37.905,4.838l-4.326-.315-2.943-3.2L26.6,2.931,22.568,1.325l-2.942,3.2L15.3,4.838,14.033,9l-3.767,2.16.661,4.3L8.476,19.047l2.451,3.588-.66,4.3,3.059,1.755-10.1,15.3,7.331-.181,2.4,6.688,9.288-14.072.321.348L26.6,35.165l4.034,1.606.32-.348,9.289,14.071,2.4-6.688,7.331.181ZM13.225,48.276l-1.97-5.487-6.14.152L14.1,29.326l1.2,3.93,4.327.316,1.925,2.092ZM26.6,34.089l-3.764,1.5L20.093,32.6l-4.038-.294-1.182-3.884-3.515-2.017.616-4.012L9.686,19.048,11.975,15.7l-.616-4.012L14.874,9.67l1.181-3.884,4.039-.294,2.744-2.984,3.764,1.5,3.765-1.5,2.744,2.984,4.038.294,1.182,3.884,3.514,2.016L41.23,15.7l2.288,3.349L41.23,22.4l.616,4.012L38.33,28.425l-1.181,3.884-4.038.294-2.744,2.984Zm15.348,8.7-1.97,5.488L31.654,35.664l1.925-2.092,4.326-.315,1.2-3.931,8.987,13.615Z" transform="translate(0)" fill="#0a4c50" stroke="#0a4c50" stroke-width="1" />
                                                            </g>
                                                        </g>
                                                    </svg>

                                                ) : index === 2 ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48.641" height="50.87" viewBox="0 0 48.641 50.87">
                                                        <g id="Group_2090" data-name="Group 2090" transform="translate(-314.282 -554.324)">
                                                            <text id="_3" data-name="3" transform="translate(339 579)" fill="#0a4c50" font-size="16" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="-4.792" y="0">3</tspan></text>
                                                            <g id="noun-medal-3593764" transform="translate(312 553.591)">
                                                                <path id="Path_177" data-name="Path 177" d="M49.988,24.229A12.514,12.514,0,1,0,37.474,36.775,12.544,12.544,0,0,0,49.988,24.229ZM37.474,35.775A11.546,11.546,0,1,1,48.988,24.229,11.543,11.543,0,0,1,37.474,35.775Z" transform="translate(-10.872 -5.182)" fill="#0a4c50" stroke="#0a4c50" stroke-width="1" />
                                                                <path id="Path_178" data-name="Path 178" d="M39.879,28.689l3.059-1.754-.661-4.3,2.451-3.588L42.277,15.46l.66-4.3L39.172,9,37.905,4.838l-4.326-.315-2.943-3.2L26.6,2.931,22.568,1.325l-2.942,3.2L15.3,4.838,14.033,9l-3.767,2.16.661,4.3L8.476,19.047l2.451,3.588-.66,4.3,3.059,1.755-10.1,15.3,7.331-.181,2.4,6.688,9.288-14.072.321.348L26.6,35.165l4.034,1.606.32-.348,9.289,14.071,2.4-6.688,7.331.181ZM13.225,48.276l-1.97-5.487-6.14.152L14.1,29.326l1.2,3.93,4.327.316,1.925,2.092ZM26.6,34.089l-3.764,1.5L20.093,32.6l-4.038-.294-1.182-3.884-3.515-2.017.616-4.012L9.686,19.048,11.975,15.7l-.616-4.012L14.874,9.67l1.181-3.884,4.039-.294,2.744-2.984,3.764,1.5,3.765-1.5,2.744,2.984,4.038.294,1.182,3.884,3.514,2.016L41.23,15.7l2.288,3.349L41.23,22.4l.616,4.012L38.33,28.425l-1.181,3.884-4.038.294-2.744,2.984Zm15.348,8.7-1.97,5.488L31.654,35.664l1.925-2.092,4.326-.315,1.2-3.931,8.987,13.615Z" transform="translate(0)" fill="#0a4c50" stroke="#0a4c50" stroke-width="1" />
                                                            </g>
                                                        </g>
                                                    </svg>

                                                ) : <p
                                                    className='text-center ml-4
                                                    text-cyan-900 font-bold text-lg md:text-xl lg:text-2xl'
                                                >
                                                    {index + 1}
                                                </p>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p
                                                className='text-center ml-4 text-cyan-900 text-lg md:text-xl lg:text-2xl'
                                            >
                                                {item.apodo}
                                            </p>
                                        </div>
                                    </td>
                                    <td className='absolute right-5'>
                                        <div>
                                            <p
                                                className='text-center ml-4 text-cyan-900 text-lg md:text-xl lg:text-2xl'
                                            >
                                                {item.puntos} PTS
                                            </p>
                                        </div>
                                    </td>
                                    
                                </tr>
                            </>
                        ))
                    }
                </tbody>
            </table>

        </div>
    )
}

export default ViewAllUser