import React, { useEffect, useState } from "react";
import { LayoutEndotrivia } from "../components/LayoutEndotrivia";
import { useNavigate } from "react-router-dom";
import { SpinnerLoading } from "../components/SpinnerLoading";
import { ValidateToken } from "../helpers/validateToken";
import { ModalInitSession } from "../components/ModalInitSession";
import { fetchConToken } from "../helpers/fetch";
const AvatarSelector = () => {
  const tokenValid = ValidateToken();
  const navigate = useNavigate();
  const [listAvatars, setListAvatars] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageSize = 200;

  useEffect(() => {
    const fetchAvatars = async () => {
      setLoading(true);
      const resp = await fetchConToken(
        `trivia/avatars?page=${page}&size=${pageSize}`
      );
      const body = await resp.json();
      setLoading(false);

      if (resp.ok) {
        if (!listAvatars) {
          setListAvatars(body.rows);
        } else {
          setListAvatars((prevList) => [...prevList, ...body.rows]);
        }
      }
    };

    fetchAvatars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100 && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const fetchUser = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const resp = await fetchConToken("users");
        const body = await resp.json();
        if (body.user && body.user.avatar && body.user.apodo) {
          navigate("/dermatrivia");
        }
      }
    };
    fetchUser();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAvatar = (avatar, url) => {
    localStorage.setItem("avatar", JSON.stringify({ avatar, url }));
    setTimeout(() => {
      navigate(`/avatar/name/${avatar}`);
    }, 500);
  };

  return !tokenValid ? (
    <ModalInitSession />
  ) : (
    <LayoutEndotrivia>
      <br />
      <p className="text-lg md:text-xl uppercase text-center text-cyan-700">
        Elije tu personaje
      </p>
      <div
        className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center items-center
                gap-4 md:gap-6 lg:gap-8
                h-full mt-7 md:mt-10 lg:mt-14"
      >
        {listAvatars ? (
          listAvatars.map((a, i) => (
            <div
              key={`avatar_${i}`}
              className="cursor-pointer shadow-sm hover:shadow-xl
                            transition duration-500 ease-in-out transform rounded-full p-2
                            hover:-translate-y-1 hover:scale-105"
              onClick={() => handleSelectAvatar(a.id, a.url)}
            >
              <img
                src={a.url}
                alt="avatar"
                className="w-[100px] h-[100px]
                                md:w-[150px] md:h-[150px]
                                lg:w-[200px] lg:h-[200px]"
              />
            </div>
          ))
        ) : (
          <SpinnerLoading />
        )}
      </div>
    </LayoutEndotrivia>
  );
};

export default AvatarSelector;
