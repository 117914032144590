import React from 'react'
import { useNavigate } from 'react-router-dom';

export const ModalInitSession = () => {
    const navigate = useNavigate();
    return (
        <div
            className="fixed inset-0 overflow-y-auto bg-gray-200 h-full w-full z-50"
        >
            <div className="w-full h-full flex justify-center items-center">
                <div className="mx-auto text-center border w-5/6 h-2/5 flex flex-col px-8 justify-center items-center shadow-lg rounded-md bg-white">
                    <div>
                        <h4 className="text-lg md:text-4xl">
                            ¡Bienvenido a DermaTrivia!
                        </h4>
                        <br />
                        <p className="text-base md:text-xl">
                            Para poder jugar, debes iniciar sesión con tu cuenta.
                        </p>
                        <br />
                        <button
                            className='bg-cyan-800 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded-full'
                            onClick={() => {
                                navigate('/endopoints-login?redirect=/avatar');
                            }}
                        >
                            Iniciar sesión
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
