
import logo from '../assets/dermatrivia_horizontal_fullcolor.svg'
import { RiArrowGoBackLine } from "react-icons/ri";

export const LayoutEndotrivia = ({ children }) => {
    return (
        <div
            className="bg-white relative w-full h-full flex flex-col justify-center items-center 
            py-10 md:py-16 lg:py-20 xl:py-24"
        >
            <button
                className='absolute hidden top-0 right-0 md:top-5 md:right-2 p-4 lg:text-2xl 
                text-black items-center gap-2
                hover:text-cyan-800'
                onClick={() => window.history.back()}
            >
                <RiArrowGoBackLine />
                Volver
            </button>
            <img
                src={logo}
                alt="logo endopoints"
                className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5"
            />
            {children}
        </div>
    )
}
