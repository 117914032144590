import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { LayoutEndotrivia } from "../components/LayoutEndotrivia";
import { ModalInitSession } from "../components/ModalInitSession";
import { ValidateToken } from "../helpers/validateToken";
import { fetchConToken } from "../helpers/fetch";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AvatarName = () => {
  const tokenValid = ValidateToken();

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [apodo, setApodo] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const { Access } = useSelector((state) => state.auth);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (Access) {
      const { name } = Access;
      setUserName(name);
    }
  }, [Access]);

  useEffect(() => {
    const avatar = localStorage.getItem("avatar") || null;
    if (avatar) {
      setUrlImage(JSON.parse(avatar).url);
    }
    const existingApodo = localStorage.getItem("apodo");
    if (existingApodo) {
      navigate("/dermatrivia");
    }
  }, [navigate]);

  const handleNextPage = async () => {
    if (apodo.trim() === "") {
      setError("Debes seleccionar un apodo para continuar");
      return;
    }
    setError("");

    const resp = await fetchConToken(
      "trivia/user",
      {
        apodo,
        avatar: urlImage,
      },
      "POST"
    );

    if(resp.ok){
      localStorage.setItem("answeredQuestions", JSON.stringify([]));
      localStorage.setItem("apodo", apodo);
      navigate("/dermatrivia");
    }
    else {
      Swal.fire({
        title: "error",
        text: "Ocurrió un error al crear su usuario para la trivia",
        icon: "error",
      });
    }
  };

  return !tokenValid ? (
    <ModalInitSession />
  ) : (
    <LayoutEndotrivia>
      <br /> <br />
      <div className="flex w-full flex-col justify-center items-center gap-4">
        <div className="flex flex-col md:flex-row items-center">
          <p className="text-base md:text-lg uppercase text-center text-cyan-700">
            Selecciona tu nombre:
          </p>
          <input
            type="text"
            placeholder="Escriba aquí…"
            className="border-2 border-gray-300 rounded-md w-[250px] md:w-[380px] md:ml-6
                        h-12 px-2 text-gray-700 focus:outline-none focus:border-cyan-700
                        placeholder:font-semibold"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>
        <div className="flex items-center flex-col md:flex-row">
          <p className="text-base md:text-lg uppercase text-center text-cyan-700">
            Selecciona tu Apodo:
          </p>
          <input
            type="text"
            placeholder="Escriba aquí…"
            className="border-2 border-gray-300 rounded-md w-[250px] md:w-[380px] md:ml-8
                                    h-12 px-2 text-gray-700 focus:outline-none focus:border-cyan-700
                                    placeholder:font-semibold"
            value={apodo}
            onChange={(e) => {
              setApodo(e.target.value);
            }}
          />
        </div>
      </div>
      <br />
      <div
        className="cursor-pointer shadow-sm
                transition duration-500 ease-in-out transform"
      >
        <img
          src={urlImage}
          alt="avatar"
          className="w-[130px] h-[130px] md:w-[150px] md:h-[150px] lg:w-[200px] lg:h-[200px]"
        />
      </div>
      {error && (
        <>
          <br />
          <span className="text-red-500 text-base font-medium"> * {error}</span>
        </>
      )}
      <div
        className="flex flex-col md:flex-row 
                justify-center items-center gap-4 mt-7 md:mt-10 lg:mt-14"
      >
        <button
          className="uppercase w-[250px]  rounded-full
                    p-3 font-bold
                    text-cyan-800 ring-1 ring-cyan-800 
                    "
          onClick={() => {
            window.history.back();
          }}
        >
          Atras
        </button>

        <button
          className="uppercase w-full rounded-full
                     bg-cyan-900 p-3 text-white font-bold"
          onClick={handleNextPage}
        >
          Continuar
        </button>
      </div>
    </LayoutEndotrivia>
  );
};

export default AvatarName;
