import React, { useCallback, useEffect, useState } from "react";
import logo from "../../assets/dermatrivia_isotipo_fullcolor.svg";
import { SpinnerLoading } from "../../components/SpinnerLoading";
import Control from "../../assets/Control.svg";
import { socket } from "../../socket";
import { fetchConToken } from "../../helpers/fetch";

export const CurrentQuestion = () => {
  const [timer, setTimer] = useState(0);
  const [percentWidth, setPercentWidth] = useState(0);
  const [question, setQuestion] = useState(null);
  const [finished, setFinished] = useState(false);
  const [questionStats, setQuestionStats] = useState(null);

  useEffect(() => {
    const handleNextQuestion = (data) => {
      setFinished(false);
      setTimer(0);
      setPercentWidth(0);
      setQuestionStats(null);
      setQuestion(data);
    };

    socket.on("next question", handleNextQuestion);
  }, []);

  const finishQuestion = useCallback(async (questionId) => {
    setFinished(true);
    setTimeout(async () => {
      fetchConToken("trivia/finish-question", { questionId }, "PATCH");
      const resp = await fetchConToken(`trivia/question-stats/${questionId}`);
      const body = await resp.json();
      setQuestionStats(body);
    }, 3000);
  }, []);

  useEffect(() => {
    let interval;
    if (!finished && question) {
      interval = setInterval(() => {
        // crea un cuenta regresiva apartir del valor de timer
        const newTime = timer + 0.01;
        setTimer(newTime);
        if (newTime >= question.timer) {
          finishQuestion(question.id);
        }
        const x = newTime / question.timer;
        setPercentWidth(Math.round(x * 100));
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [finishQuestion, finished, question, timer]);

  return question ? (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-[90%] md:w-[80%] py-5 flex flex-col justify-center items-center">
        <div className="bg-cyan-900 w-full h-16 px-5 py-2 rounded-t-lg relative shadow-md">
          <img
            src={logo}
            className="w-[55px] filter invert brightness-0"
            alt="logo endopoints"
          />
          <p
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                    font-bold text-white text-lg md:text-xl lg:text-2xl xl:text-3xl uppercase"
          >
            Pregunta
          </p>
        </div>
        <div
          dangerouslySetInnerHTML={
            { __html: question.pregunta } // eslint-disable-line
          }
          className="bg-[#E7F4F5] w-full px-5 py-2 md:px-8 md:py-4 rounded-b-lg
                    text-base md:text-lg shadow-md"
        ></div>

        <br />
        {question.opciones.map((answer, index) => (
          <div
            key={answer.id}
            className={`${finished && answer.correcta && questionStats ? "bg-lime-500" : "bg-[#E7F4F5]"} w-full px-5 py-2 md:px-8 md:py-4 rounded-lg text-base md:text-lg shadow-md flex flex-col sm:flex-row justify-center items-center my-3`}
          >
            <div className="text-xl font-bold text-cyan-900">
              {answer.texto}
              {finished && questionStats && questionStats[answer.id.toString()] !== undefined
                ? ` (${parseFloat(questionStats[answer.id.toString()]) * 100}%)`
                : ""}
            </div>
          </div>
        ))}
      </div>

      {!finished && (
        <div className="relative pt-1 w-[90%] md:w-[80%]">
          <div
            className="mx-auto font-bold mb-3
                        text-cyan-900 text-lg md:text-xl lg:text-2xl xl:text-3xl text-center"
          >
            {timer ? timer.toFixed(2) : ""} / {question.timer} segundos
          </div>
          <div className="overflow-hidden w-full h-3 md:h-5 mb-4 text-xs flex rounded-full bg-cyan-100">
            <div
              style={{
                width: `${percentWidth}%`,
              }}
              className="shadow-none 
                    transition-all duration-500 ease-in-out
                  flex flex-col text-center whitespace-nowrap text-white justify-center bg-cyan-800"
            ></div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
      <img
        src={Control}
        className="w-[55px] md:w-[80px] lg:w-[100px] 
          mx-auto animate-wiggle animate-infinite -bottom-8 relative
          animate-duration-500 animate-delay-500 animate-ease-in"
        alt="logo endopoints"
      />
      <p
        className="text-base md:text-lg lg:text-xl text-cyan-900 font-semibold mt-4
      -bottom-10 relative text-center uppercase
      "
      >
        Esperando la siguiente pregunta …
      </p>
      <div className="">
        <SpinnerLoading isFullPage={false} />
      </div>
    </div>
  );
};
