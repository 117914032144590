import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AnswersQuestions } from "./Answers_Questions";

const DermaTrivia = ({ activeQuestion, sendAnswerToServer, setShowModal, timer }) => {
  const [userName, setUserName] = useState("");
  const [urlImage, setUrlImage] = useState("");

  useEffect(() => {
    localStorage.getItem("apodo") && setUserName(localStorage.getItem("apodo"));
  }, []);

  useEffect(() => {
    const avatar = localStorage.getItem("avatar") || null;
    if (avatar) {
      setUrlImage(JSON.parse(avatar).url);
    }
  }, []);

  const handleAnswer = (answer) => {
    if (answer.correcta) {
      const x = activeQuestion.puntosPorUsuario * (timer / activeQuestion.timer);
      sendAnswerToServer(answer.id, Math.round(x));
    } else {
      sendAnswerToServer(answer.id, 0);
    }
    localStorage.setItem("answeredQuestions", JSON.stringify([...JSON.parse(localStorage.getItem("answeredQuestions")), activeQuestion.id]));
  };

  return (
    <div className="max-w-screen-2xl min-h-screen relative overflow-hidden">
      <div className="absolute top-3 right-3 md:top-5 md:right-5 flex gap-4 items-center">
        <p className="text-gray-500">{userName}</p>
        <img
          src={
            urlImage ||
            "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
          }
          alt="avatar"
          className=" w-[40px] h-[40px] md:w-[60px] md:h-[60px]"
        />
      </div>
      <AnswersQuestions timer={timer} pregunta={activeQuestion} handleAnswer={handleAnswer} />
    </div>
  );
};

export default DermaTrivia;
