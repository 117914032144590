import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "User",
  initialState: {
    userInfo: [],
    premios: [],
    triviaUser: null,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setPremios: (state, action) => {
      state.premios = action.payload;
    },
    setTriviaUser: (state, action) => {
      state.triviaUser = action.payload;
    },
  },
});

export const { setUserInfo, setPremios, setTriviaUser } = userSlice.actions;
